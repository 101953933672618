import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  protectedAccounts;
  activeProtectedAccount;

  constructor(private dashboardService: DashboardService, private toast: ToastrService, private spinner: NgxSpinnerService, private router: Router) {

  }

  getData() {
    this.spinner.show();
    this.dashboardService.dataFromDashboard().subscribe((data) => {
      this.protectedAccounts = data.protectedAccount;
      if (!this.changeAccount('instagram')) {
        if (!this.changeAccount('youtube')) {
          if (!this.changeAccount('twitter')) {
            if (!this.changeAccount('facebook')) {
              if (!this.changeAccount('linkedin')) {
                if (!this.changeAccount('domain')) {
                  this.toast.warning('Korunan hiç hesabınız yok. Hemen bir tane ekleyebilirsiniz.');
                }
              }
            }
          }
        }
      }
      this.spinner.hide();
    }, (err) => {
      this.toast.error(err.message);
      this.spinner.hide();
    });

  }

  issetData(data: any) {
    if (data.type === 'instagram') {
      if (this.protectedAccounts[0].InstagramProtectedAccount.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (data.type === 'youtube') {
      if (this.protectedAccounts[1].YoutubeProtectedAccount.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (data.type === 'twitter') {
      if (this.protectedAccounts[2].TwitterProtectedAccount.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (data.type === 'facebook') {
      if (this.protectedAccounts[3].FacebookProtectedAccount.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (data.type === 'linkedin') {
      if (this.protectedAccounts[4].LinkedinProtectedAccount.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    else if (data.type === 'domain') {
      if (this.protectedAccounts[5].domainAccount.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  changeAccount(type: any, isNotEmpty: boolean = true) {
    if (isNotEmpty) {
      if (type === 'instagram') {
        if (this.protectedAccounts[0].InstagramProtectedAccount) {
          this.activeProtectedAccount = this.protectedAccounts[0];
          return true;
        } else {
          return false;
          // this.toast.error('Hiç instagram hesabı eklenmemiş.');
        }
      } else if (type === 'youtube') {
        if (this.protectedAccounts[1].YoutubeProtectedAccount) {
          this.activeProtectedAccount = this.protectedAccounts[1];
          return true;
        } else {
          return false;
          // this.toast.error('Hiç instagram hesabı eklenmemiş.');
        }
      } else if (type === 'twitter') {
        if (this.protectedAccounts[2].TwitterProtectedAccount) {
          this.activeProtectedAccount = this.protectedAccounts[2];
          return true;
        } else {
          return false;
          // this.toast.error('Hiç twitter hesabı eklenmemiş.');
        }
      } else if (type === 'facebook') {
        if (this.protectedAccounts[3].FacebookProtectedAccount) {
          this.activeProtectedAccount = this.protectedAccounts[3];
          return true;
        } else {
          return false;
          // this.toast.error('Hiç twitter hesabı eklenmemiş.');
        }
      } else if (type === 'linkedin') {
        if (this.protectedAccounts[4].LinkedinProtectedAccount) {
          this.activeProtectedAccount = this.protectedAccounts[4];
          return true;
        } else {
          return false;
          // this.toast.error('Hiç twitter hesabı eklenmemiş.');
        }
      }
      else if (type === 'domain') {
        if (this.protectedAccounts[5].domainAccount) {
          this.activeProtectedAccount = this.protectedAccounts[5];
          return true;
        } else {
          return false;
          // this.toast.error('Hiç twitter hesabı eklenmemiş.');
        }
      }
      else {
        // this.activeProtectedAccount = this.protectedAccounts[0];
      }
      return false;
    } else {
      let extra: NavigationExtras = {
        state: {
          topic: 'ÜYELİK',
          title: type + ' paketini aktif etmek istiyorum.',
          desc: 'İşlem yaparken hesabıma tanımlı olmadığını fark ettim. Gerekli işlemlerin yapılmasını istiyorum.'
        },
        replaceUrl: true
      };
      this.toast.info('Paket hesabınıza tanımlı değil. Açmak için iletişme geçebilirsiniz.', 'Bilgilendirme');
      this.router.navigate(['support'], extra);
    }
  }

  ngOnInit(): void {
    this.getData();
  }

}
