import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-new-account1',
  templateUrl: './new-account1.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccount1Component implements OnInit {
  accountData = {
    socials: {
      facebook: undefined,
      instagram: undefined,
      twitter: undefined,
      youtube: undefined,
    },
    isBrand: undefined,
    email: undefined,
    fullName: undefined,
    phone: undefined
  };

  constructor(private toast: ToastrService, private router: Router) {
    let data = this.router.getCurrentNavigation().extras.state?.data;
    if (!data) {
      this.router.navigate(['new-account'], {replaceUrl: true});
    } else {
      this.accountData = data;
    }
  }

  ngOnInit(): void {
  }

  selectPlatform(platform: string) {
    if (this.accountData.socials[platform]) {
      this.accountData.socials[platform] = undefined;
    } else {
      this.accountData.socials[platform] = true;
    }
  }

  continue() {
    if (!this.accountData.socials.instagram && !this.accountData.socials.facebook && !this.accountData.socials.twitter && !this.accountData.socials.youtube) {
      this.toast.error('Lütfen bir platform seçiniz');
    } else {
      let navigationExtras: NavigationExtras = {
        state: {
          data: this.accountData
        }
      };
      this.router.navigate(['new-account/step2'], navigationExtras);
    }
  }
}
