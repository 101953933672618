<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>Dashboard - FakeRadar</title>
  <link href="https://cdn.jsdelivr.net/npm/remixicon@2.2.0/fonts/remixicon.css" rel="stylesheet">
</head>
<body>
<app-header></app-header>
<div class="main">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
</body>
</html>
