import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {catchError, tap, map} from 'rxjs/operators';
import {GeneralService} from './general.service';
import {ToastrService} from 'ngx-toastr';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'JWT ' + localStorage.getItem('token')})
};


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient, private general: GeneralService, private toast: ToastrService) {


  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `All error: ${JSON.stringify(error)}` +
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // if (error.status === 403) {
    //   console.log("giriyor");
    //   this.toast.error('KİMLİK DOĞRULAMA HATASI');
    // }

    return throwError(`${JSON.stringify(error.error)}`);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  createAccount(userInfo): Observable<any> {
    return this.http.post(this.general.apiUrl + '/user/createaccount', userInfo, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSuspectedAccounts(): Observable<any> {
    return this.http.get(this.general.apiUrl + '/user/suspectedlist', httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getWhiteListAccounts(): Observable<any> {
    return this.http.get(this.general.apiUrl + '/user/whitelist', httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPendingLists(): Observable<any> {
    return this.http.get(this.general.apiUrl + '/user/pendinglist', httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getProtectedAccounts(): Observable<any> {
    return this.http.get(this.general.apiUrl + '/user/protectedaccountlist', httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTakeDownList(): Observable<any> {
    return this.http.get(this.general.apiUrl + '/user/takendownlist', httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletePending(searchId, Type) {
    return this.http.post(this.general.apiUrl + '/user/deletepending', {search_id: searchId, type: Type}, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  addWhiteList(blacklistId) {
    return this.http.post(this.general.apiUrl + '/user/addwhitelist', {blacklist_id: blacklistId}, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  changePassword(old_password, new_password) {
    return this.http.put(this.general.apiUrl + '/user/changepassword', {oldPassword: old_password, newPassword: new_password}, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  removeWhiteList(blacklistId) {
    return this.http.post(this.general.apiUrl + '/user/removewhitelist', {blacklist_id: blacklistId}, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  doTakeDown(blacklistId) {
    return this.http.post(this.general.apiUrl + '/user/takedown', {blacklist_id: blacklistId}, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  sendMessage(category, title, desc) {
    return this.http.post(this.general.apiUrl + '/user/helpdesk', {'category': category, 'baslik': title, 'text': desc}, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

}
