import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-new-account6',
  templateUrl: './new-account6.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccount6Component implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  goNewOne() {
    this.router.navigate(['new-account'], {replaceUrl: true});
  }
}
