import {Component, OnInit} from '@angular/core';
import {AccountService} from '../services/account-service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

;

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {

  suspectedAccounts: any;
  is_showListMenu: boolean = false;
  showMenuId = false;
  p = 1;
  searchKey: any;

  constructor(private accountService: AccountService, private toast: ToastrService, private spinner: NgxSpinnerService) {
    this.spinner.show();
    this.accountService.getSuspectedAccounts().subscribe((data) => {
      this.spinner.hide();
      this.suspectedAccounts = data.data;
    }, (err) => {
      this.spinner.hide();
      this.toast.error(err.message);
    });
  }

  ngOnInit(): void {
  }

  showListMenu(b: boolean, menu_id: any = false) {
    console.log(menu_id);
    this.is_showListMenu = b;
    this.showMenuId = menu_id;
  }

  addWhiteList(blacklist_id: any) {
    this.spinner.show();
    this.accountService.addWhiteList(blacklist_id).subscribe((data: any) => {
      this.spinner.hide();
      this.toast.success(data.message);
      window.location.reload();
    }, (err) => {
      this.toast.error('Hata');
      this.spinner.show();
    });
  }

  doTakeDown(blacklist_id) {
    this.spinner.show();
    this.accountService.doTakeDown(blacklist_id).subscribe((data: any) => {
      this.spinner.hide();
      this.toast.success(data.message);
      window.location.reload();
    }, (err) => {
      this.toast.error(JSON.parse(err).message, 'Hata');
      this.spinner.hide();
    });
  }

  goProfile(data: any) {
    if (data.type === 'instagram') {
      window.open('https://www.' + data.type + '.com/' + data.username, '_blank');
    } else if (data.type === 'twitter') {
      window.open('https://www.' + data.type + '.com/' + data.username, '_blank');
    } else if (data.type === 'youtube') {
      window.open('https://www.' + data.type + '.com/' + data.username, '_blank');
    } else if (data.is_takedown === 1) {
      window.open('https://www.' + data.type + '.com/' + data.username, '_blank');
    }
  }
}
