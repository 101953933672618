import {Component, OnInit} from '@angular/core';
import {AccountService} from '../services/account-service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-takedown-accounts',
  templateUrl: './takedown-accounts.component.html',
  styleUrls: ['./takedown-accounts.component.scss']
})
export class TakedownAccountsComponent implements OnInit {

  takeDownAccounts: any;
  p = 1;
  searchKey: any;

  constructor(private accountService: AccountService, private toast: ToastrService, private spinner: NgxSpinnerService) {
    this.spinner.show();
    this.accountService.getTakeDownList().subscribe((data) => {
      // console.log(data);
      this.spinner.hide();
      this.takeDownAccounts = data.data;
    }, (err) => {
      this.spinner.hide();
      this.toast.error(err.message);
    });
  }


  goProfile(data: any) {
    window.open('https://www.' + data.type + '.com/' + data.suspected_username, '_blank');
  }

  ngOnInit(): void {
  }

}
