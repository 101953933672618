import {Component, OnInit} from '@angular/core';
import {AccountService} from '../services/account-service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-pending-lists',
  templateUrl: './pending-lists.component.html',
  styleUrls: ['./pending-lists.component.scss']
})
export class PendingListsComponent implements OnInit {

  youtubePendingList;
  instagramPendingList;
  twitterPendingList;
  takeDownPendingList;
  mixPendings;

  suspectedAccounts: any;
  is_showListMenu: boolean = false;
  showMenuId = false;
  showMenuType: any;

  p: number = 1;
  collection: any[];
  searchKey: any;

  constructor(private accountService: AccountService, private toast: ToastrService, private spinner: NgxSpinnerService) {

  }

  showListMenu(b: boolean, menu_id: any = false, menu_type: any = false) {
    this.is_showListMenu = b;
    this.showMenuId = menu_id;
    this.showMenuType = menu_type;
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  getData() {
    this.spinner.show();
    this.accountService.getPendingLists().subscribe((data) => {
      // console.log(data);
      // data.dataPendingList[0].youtubePendingList['type'] = 'youtube';
      // data.dataPendingList[1].instagramPendingList['type'] = 'instagram';
      // data.dataPendingList[2].youtubePendingList['type'] = 'twitter';
      // data.dataPendingList[3].takeDownPendingList['type'] = 'takeDown';

      this.youtubePendingList = data.dataPendingList[0].youtubePendingList;
      this.instagramPendingList = data.dataPendingList[1].instagramPendingList;
      this.twitterPendingList = data.dataPendingList[2].twitterPendingList;
      this.takeDownPendingList = data.dataPendingList[3].takeDownPendingList;
      let allPending = this.shuffle([...this.youtubePendingList, ...this.instagramPendingList, ...this.twitterPendingList, ...this.takeDownPendingList]);
      this.mixPendings = allPending;
      // this.mixPendings = allPending.sort((a, b) => b.created_date - a.created_date);
      this.mixPendings.sort((val1, val2) => {
        return new Date(val2.created_date).getTime() - new
        Date(val1.created_date).getTime();
      });
      // console.log(this.mixPendings);

      this.spinner.hide();

    }, (err) => {
      this.toast.error(err.message);
      this.spinner.hide();
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  deletePending(search_id: any, type: any) {
    this.accountService.deletePending(search_id, type).subscribe((data: any) => {
      this.toast.success(data.message);
      // console.log(data);
    }, (err) => {
      this.toast.error(err.message);
    });
  }

  goProfile(type: any, username: any) {
    window.open('https://www.' + type + '.com/' + username, '_blank');
  }
}
