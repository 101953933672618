<div class="content">
  <div class="container">
    <div class="dashboard-area" *ngIf="this.activeProtectedAccount">
      <div class="dashboard-top">
        <ul>
          <li
            *ngFor="let data of this.protectedAccounts"
            (click)="changeAccount(data.type, issetData(data))"
            [class.active]="this.activeProtectedAccount.type === data.type"
            [class.empty]="!issetData(data)"
          >
            {{ data.type }}
          </li>
          <!--          <li (click)="changeAccount('instagram')" [class.empty]="instagram" [class.active]="this.activeProtectedAccount.type === 'instagram'">-->
          <!--            INSTAGRAM-->
          <!--          </li>-->
          <!--          <li (click)="changeAccount('youtube')" [class.active]="this.activeProtectedAccount.type === 'youtube'">-->
          <!--            YOUTUBE-->
          <!--          </li>-->
          <!--          <li (click)="changeAccount('twitter')" [class.active]="this.activeProtectedAccount.type === 'twitter'">-->
          <!--            TWITTER-->
          <!--          </li>-->
        </ul>
        <div class="dashboard-top-right">
          <!--          <div class="dashboard-top-right-icon">-->
          <!--            <i class="fa fa-calendar-check"></i>-->
          <!--          </div>-->
          <!--          <div class="dashboard-top-right-last">-->
          <!--            <p>SON KONTROL TARİHİ</p>-->
          <!--            <p><span class="date">01.01.2020</span>'den <span class="date">20.01.2020</span>'ye kadar-->
          <!--            </p>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="dashboard-cards">
        <ul>
          <li>
            <span class="icon green">
              <i class="fa fa-shield"></i>
            </span>
            <span class="number">{{
              this.activeProtectedAccount.statistics.protectedCount
            }}</span>
            <span class="text" translate
              >protecting <span class="text-br" translate>account</span></span
            >
          </li>
          <li>
            <span class="icon blue">
              <i class="fa fa-user"></i>
            </span>
            <span class="number">{{
              this.activeProtectedAccount.statistics.suspectedCount
            }}</span>
            <span class="text" translate
              >founded <span class="text-br" translate>account</span></span
            >
          </li>
          <li>
            <span class="icon yellow">
              <i class="fa fa-times-circle"></i>
            </span>
            <span class="number">{{
              this.activeProtectedAccount.statistics.takeDownedCount
            }}</span>
            <span class="text" translate
              >closedFake <span class="text-br" translate>account</span></span
            >
          </li>
          <li>
            <span class="icon red">
              <i class="fa fa-exclamation-circle"></i>
            </span>
            <!-- <span class="number">{{this.activeProtectedAccount.statistics.danger_level}}</span> -->
            <span translate class="number">low</span>
            <span translate class="text"
              >risk <span class="text-br" translate>level</span></span
            >
          </li>
        </ul>
      </div>
      <div translate class="dashboard-guard-title">
        protectedAcc
        <span class="icons">
          <i class="fa fa-chevron-left"></i>
          <i class="fa fa-chevron-right"></i>
        </span>
      </div>

      <ng-scrollbar
        class="my-scroll"
        #scrollable
        track="all"
        pointerEventsMethod="scrollbar"
        track="horizontal"
        appearance="compact"
      >
        <div class="dashboard-guarded-accounts">
          <div
            class="scroll-content"
            *ngIf="activeProtectedAccount.YoutubeProtectedAccount"
          >
            <div
              *ngFor="
                let account of activeProtectedAccount.YoutubeProtectedAccount
              "
              class="guarded-account"
            >
              <div class="guarded-account-image">
                <img src="{{ account.profile_photo }}" />
              </div>
              <div class="guarded-account-title">
                {{ account.youtube_title }}
              </div>
              <div class="guarded-account-username">
                @{{ account.youtube_username }}
              </div>
              <!--            <div class="guarded-account-risk">-->
              <!--              {{acti}}78% RİSK SEVİYESİ-->
              <!--            </div>-->
              <div class="guarded-account-scores">
                <ul>
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-poll"></i>
                    </span>
                    <span class="score-number">{{
                      account.post | shortNumber
                    }}</span>
                    <span translate="" class="score-title">post</span>
                  </li>
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-play"></i>
                    </span>
                    <span class="score-number">{{
                      account.viewer_count | shortNumber
                    }}</span>
                    <span translate class="score-title">views</span>
                  </li>
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-user"></i>
                    </span>
                    <span class="score-number">{{
                      account.subscriberCount | shortNumber
                    }}</span>
                    <span translate class="score-title">subscribe</span>
                  </li>
                </ul>
              </div>
              <!--            <div class="guarded-account-button">-->
              <!--              <button>DETAY GÖR</button>-->
              <!--            </div>-->
            </div>
          </div>
          <div
            class="scroll-content"
            *ngIf="activeProtectedAccount.InstagramProtectedAccount"
          >
            <div
              *ngFor="
                let account of activeProtectedAccount.InstagramProtectedAccount
              "
              class="guarded-account"
            >
              <div class="guarded-account-image">
                <img src="{{ account.profile_photo }}" />
              </div>
              <div class="guarded-account-title">
                {{ account.instagram_fullname }}
              </div>
              <div class="guarded-account-username">
                @{{ account.instagram_username }}
              </div>
              <!--            <div class="guarded-account-risk">-->
              <!--              {{acti}}78% RİSK SEVİYESİ-->
              <!--            </div>-->
              <div class="guarded-account-scores">
                <ul>
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-poll"></i>
                    </span>
                    <span class="score-number">{{
                      account.post | shortNumber
                    }}</span>
                    <span translate class="score-title">post</span>
                  </li>
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-users"></i>
                    </span>
                    <span class="score-number">{{
                      account.follower | shortNumber
                    }}</span>
                    <span translate class="score-title">follower</span>
                  </li>
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-user-plus"></i>
                    </span>
                    <span class="score-number">{{
                      account.following | shortNumber
                    }}</span>
                    <span translate class="score-title">follow</span>
                  </li>
                </ul>
              </div>
              <!--            <div class="guarded-account-button">-->
              <!--              <button>DETAY GÖR</button>-->
              <!--            </div>-->
            </div>
          </div>
          <div
            class="scroll-content"
            *ngIf="activeProtectedAccount.TwitterProtectedAccount"
          >
            <div
              *ngFor="
                let account of activeProtectedAccount.TwitterProtectedAccount
              "
              class="guarded-account"
            >
              <div class="guarded-account-image">
                <img src="{{ account.profile_photo }}" />
              </div>
              <div class="guarded-account-title">
                {{ account.twitter_fullname }}
              </div>
              <div class="guarded-account-username">
                @{{ account.twitter_username }}
              </div>
              <!--            <div class="guarded-account-risk">-->
              <!--              {{acti}}78% RİSK SEVİYESİ-->
              <!--            </div>-->
              <div class="guarded-account-scores">
                <ul>
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-poll"></i>
                    </span>
                    <span class="score-number">{{
                      account.post | shortNumber
                    }}</span>
                    <span translate class="score-title">post</span>
                  </li>
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-users"></i>
                    </span>
                    <span class="score-number">{{
                      account.follower | shortNumber
                    }}</span>
                    <span translate class="score-title">follower</span>
                  </li>
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-user-plus"></i>
                    </span>
                    <span class="score-number">{{
                      account.following | shortNumber
                    }}</span>
                    <span translate class="score-title">follow</span>
                  </li>
                </ul>
              </div>
              <!--            <div class="guarded-account-button">-->
              <!--              <button>DETAY GÖR</button>-->
              <!--            </div>-->
            </div>
          </div>
          <div
            class="scroll-content"
            *ngIf="activeProtectedAccount.FacebookProtectedAccount"
          >
            <div
              *ngFor="
                let account of activeProtectedAccount.FacebookProtectedAccount
              "
              class="guarded-account"
            >
              <div class="guarded-account-image">
                <img src="{{ account.profile_photo }}" />
              </div>
              <div class="guarded-account-title">
                {{ account.facebook_fullname }}
              </div>
              <div class="guarded-account-username">
                @{{ account.facebook_username }}
              </div>
              <!--            <div class="guarded-account-risk">-->
              <!--              {{acti}}78% RİSK SEVİYESİ-->
              <!--            </div>-->
              <div class="guarded-account-scores">
                <ul>
                  <!-- <li>
                                    <span class="score-icon">
                                        <i class="fa fa-poll"></i>
                                     </span>
                    <span class="score-number">{{account.post | shortNumber}}</span>
                    <span class="score-title">Post</span>
                  </li> -->
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-users"></i>
                    </span>
                    <span class="score-number">{{
                      account.follower | shortNumber
                    }}</span>
                    <span translate class="score-title">follower</span>
                  </li>
                  <!-- <li>
                                    <span class="score-icon">
                                        <i class="fa fa-user-plus"></i>
                                     </span>
                    <span class="score-number">{{account.following | shortNumber}}</span>
                    <span class="score-title">Takip</span>
                  </li> -->
                </ul>
              </div>
              <!--            <div class="guarded-account-button">-->
              <!--              <button>DETAY GÖR</button>-->
              <!--            </div>-->
            </div>
          </div>
          <div
            class="scroll-content"
            *ngIf="activeProtectedAccount.LinkedinProtectedAccount"
          >
            <div
              *ngFor="
                let account of activeProtectedAccount.LinkedinProtectedAccount
              "
              class="guarded-account"
            >
              <div class="guarded-account-image">
                <img src="{{ account.profile_photo }}" />
              </div>
              <div class="guarded-account-title">
                {{ account.linkedin_fullname }}
              </div>
              <div class="guarded-account-username">
                @{{ account.linkedin_username }}
              </div>
              <!--            <div class="guarded-account-risk">-->
              <!--              {{acti}}78% RİSK SEVİYESİ-->
              <!--            </div>-->
              <div class="guarded-account-scores">
                <ul>
                  <!-- <li>
                                    <span class="score-icon">
                                        <i class="fa fa-poll"></i>
                                     </span>
                    <span class="score-number">{{account.post | shortNumber}}</span>
                    <span class="score-title">Post</span>
                  </li> -->
                  <li>
                    <span class="score-icon">
                      <i class="fa fa-users"></i>
                    </span>
                    <span class="score-number">{{
                      account.follower | shortNumber
                    }}</span>
                    <span translate class="score-title">follower</span>
                  </li>
                  <!-- <li>
                                    <span class="score-icon">
                                        <i class="fa fa-user-plus"></i>
                                     </span>
                    <span class="score-number">{{account.following | shortNumber}}</span>
                    <span class="score-title">Takip</span>
                  </li> -->
                </ul>
              </div>
              <!--            <div class="guarded-account-button">-->
              <!--              <button>DETAY GÖR</button>-->
              <!--            </div>-->
            </div>
          </div>
          <div
            class="scroll-content"
            *ngIf="activeProtectedAccount.domainAccount"
          >
            <div
              *ngFor="let account of activeProtectedAccount.domainAccount"
              class="guarded-account"
            >
              <div class="guarded-account-image">
                <img src="{{ account.profile_photo }}" />
              </div>
              <div class="guarded-account-title" style="font-size: 18px">
                {{ account.domainame }}
              </div>
              <!-- <div class="guarded-account-username">
                @{{account.linkedin_username}}
              </div> -->
              <!--            <div class="guarded-account-risk">-->
              <!--              {{acti}}78% RİSK SEVİYESİ-->
              <!--            </div>-->
              <div class="guarded-account-scores">
                <ul>
                  <!-- <li>
                                    <span class="score-icon">
                                        <i class="fa fa-poll"></i>
                                     </span>
                    <span class="score-number">{{account.post | shortNumber}}</span>
                    <span class="score-title">Post</span>
                  </li> -->
                  <!--        <li>
                                    <span class="score-icon">
                                        <i class="fa fa-users"></i>
                                     </span>
                    <span class="score-number">{{account.follower | shortNumber}}</span>
                    <span class="score-title">Takipçi</span>
                  </li> -->
                  <!-- <li>
                                    <span class="score-icon">
                                        <i class="fa fa-user-plus"></i>
                                     </span>
                    <span class="score-number">{{account.following | shortNumber}}</span>
                    <span class="score-title">Takip</span>
                  </li> -->
                </ul>
              </div>
              <!--            <div class="guarded-account-button">-->
              <!--              <button>DETAY GÖR</button>-->
              <!--            </div>-->
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
