  <div class="content">
  <div class="container">
    <div translate class="prev-page"><i class="fa fa-arrow-left"></i> previous</div>
    <div translate class="divider-title">
      supportC
    </div>
    <div class="support-area">
      <div class="support-left">
        <div translate class="support-title">
          <span class="icon"><i class="fa fa-headphones"></i></span>supportRequest
        </div>
        <div class="support-topic">
          <h3 translate>whatsYourProblemTopic</h3>
          <ul>
            <li *ngFor="let category of categories" [class.active]="activeCategory == category" (click)="changeCategory(category)">{{category}}</li>
          </ul>
        </div>
        <div class="form-content">
          <div class="form-group">
            <label translate for="subject">header</label>
            <input type="text" id="subject" [(ngModel)]="title">
          </div>
          <div class="form-group">
            <label translate for="description">description</label>
            <textarea id="description" rows="4" [(ngModel)]="desc"></textarea>
          </div>
          <div class="form-button">
            <button (click)="sendMail()" (keyup.enter)="sendMail()" translate>send</button>
          </div>
        </div>
        <div class="support-extra-info">
          <p>Mail: <span class="black-text">info@fakeradar.com</span></p>
          <p translate>toSupport <span translate class="black-text">in24h </span>return</p>
        </div>
      </div>
      <div class="support-right">
        <img src="assets/img/support-area.png">
      </div>

    </div>
  </div>
</div>
  <ngx-spinner></ngx-spinner>
