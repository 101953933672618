import {Component, OnInit} from '@angular/core';
import {AccountService} from '../services/account-service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Location} from '@angular/common';

@Component({
  selector: 'app-whitelist-accounts',
  templateUrl: './whitelist-accounts.component.html',
  styleUrls: ['./whitelist-accounts.component.scss']
})
export class WhitelistAccountsComponent implements OnInit {

  whiteListAccounts: any;
  is_showListMenu: boolean = false;
  showMenuId = false;
  p = 1;
  searchKey: any;

  constructor(private accountService: AccountService, private toast: ToastrService, private spinner: NgxSpinnerService, public _location: Location) {
    this.spinner.show();
    this.accountService.getWhiteListAccounts().subscribe((data) => {
      this.spinner.hide();
      this.whiteListAccounts = data.data;
    }, (err) => {
      this.spinner.hide();
      this.toast.error(err.message);
    });
  }

  ngOnInit(): void {
  }

  showListMenu(b: boolean, menu_id: any = false) {
    this.is_showListMenu = b;
    this.showMenuId = menu_id;
  }

  removeWhiteList(blacklist_id: any) {
    this.accountService.removeWhiteList(blacklist_id).subscribe((data: any) => {
      this.toast.success(data.message);
      window.location.reload();

    }, (err) => {
      this.toast.error(err.message);
    });
  }

  goProfile(data: any) {
    if (data.type === 'instagram') {
      window.open('https://www.' + data.type + '.com/' + data.username, '_blank');
    } else if (data.type === 'twitter') {
      window.open('https://www.' + data.type + '.com/' + data.username, '_blank');
    } else if (data.type === 'youtube') {
      window.open('https://www.' + data.type + '.com/' + data.username, '_blank');
    } else if (data.is_takedown === 1) {
      window.open('https://www.' + data.type + '.com/' + data.username, '_blank');
    }
  }

}
