<header>
  <div class="header-logo">
    <a href="#"><img src="assets/img/logo.svg" alt="FakeRadar Logo"></a>
  </div>
  <div class="header-long">
    <div class="header-long-search">
      <div class="header-long-search-icon">
<!--        <i class="fal fa-search"></i>-->
      </div>
      <div class="header-long-search-input">
<!--        <input type="text" name="search" id="header-search" placeholder="Ne aramıştınız?" autocomplete="off">-->
      </div>
    </div>
    <div class="header-long-end">
      <div class="header-long-end-info">
        <p translate>welcome</p>
        <h3>{{localStorageItem('namesurname')}}</h3>
      </div>
      <div class="header-long-end-image">
<!--        <img src="./.assets/img/selimhancakir.png" alt="Selimhan Çakır">-->
        <!--        <span class="arrow">-->
        <!--                    <i class="fa fa-chevron-down"></i>-->
        <!--                </span>-->
      </div>
    </div>
  </div>
</header>


