<div class="content">
  <div class="container">
    <div translate class="prev-page" (click)="_location.back()"><i class="fa fa-arrow-left"></i> previous</div>
    <div class="list-top">
      <div translate class="divider-title">
        trustedAccC
      </div>
      <div class="account-search-input">
        <input type="search" placeholder="Search.." [(ngModel)]="searchKey">
      </div>
    </div>
      <div class="list-accounts">
        <div class="list-accounts-item" *ngFor="let account of this.whiteListAccounts |filter: searchKey | paginate: { itemsPerPage: 10, currentPage: p }">
          <div class="list-accounts-item-platform">
            <i class="fab fa-facebook" *ngIf="account.type == 'facebook'"></i>
            <i class="fab fa-twitter-square" *ngIf="account.type == 'twitter'"></i>
            <i class="fab fa-instagram" *ngIf="account.type == 'instagram'"></i>
            <i class="fab fa-youtube" *ngIf="account.type == 'youtube'"></i>
          </div>
          <div class="list-accounts-item-image" (click)="goProfile(account)">
            <img src="{{account.suspected_photo}}">
          </div>
          <div class="list-accounts-item-title" (click)="goProfile(account)">
          <span class="title"
                tooltip="{{(account.full_name) ? account.full_name: account.username}}">{{(account.full_name) ? account.full_name: account.username}}</span>
            <span class="url">@{{account.username}}</span>
          </div>
          <div class="list-accounts-item-divider"></div>
          <div class="list-accounts-item-number">
            <div class="list-accounts-item-number-icon"><i class="fa fa-poll-h"></i></div>
            <div class="list-accounts-item-number-content">
              <span class="number">{{account.post | shortNumber}}</span>
              <span translate class="title">postC</span>
            </div>
          </div>
          <div class="list-accounts-item-number">
            <div class="list-accounts-item-number-icon"><i class="fa fa-users"></i></div>
            <div class="list-accounts-item-number-content">
              <span class="number">{{account.follower | shortNumber}}</span>
              <span translate class="title">followerC</span>
            </div>
          </div>
          <div class="list-accounts-item-number">
            <div class="list-accounts-item-number-icon"><i class="fa fa-user-plus"></i></div>
            <div class="list-accounts-item-number-content">
              <span class="number">{{account.following | shortNumber}}</span>
              <span translate class="title">followC</span>
            </div>
          </div>
          <!--        <div class="list-accounts-item-divider"></div>-->
          <!--        <div class="list-accounts-item-mail">-->
          <!--          <i class="fa fa-envelope"></i>-->
          <!--          <span class="mail">hello@burakodabas.net</span>-->
          <!--        </div>-->
          <div class="list-accounts-item-divider"></div>
          <div class="list-accounts-item-status">
            <span translate class="status-title">activityStatus</span>
            <!--          <span class="status-text"-->
            <!--                [class.active]="account.is_whitelist">{{(account.is_whitelist) ? 'Aktif' : 'Pasif'}}</span>-->
            <span translate class="status-text active">active</span>
          </div>
          <div class="list-accounts-item-divider"></div>
          <div class="list-accounts-item-date">
            <span translate class="date-title">startDate</span>
            <div class="date-text">{{account.created_date | date : 'dd.MM.yyyy'}}</div>
          </div>
          <div class="list-accounts-item-button" (mouseenter)="showListMenu(true, account.blacklist_id)"
               (mouseleave)="showListMenu(false)">
            <button translate>operationsC</button>
            <ul class="list-acount-item-button-menu"
                [class.open-menu]="is_showListMenu && account.blacklist_id === showMenuId">
              <li translate (click)="removeWhiteList(account.blacklist_id)">removeTrusted</li>
            </ul>
          </div>
        </div>
      </div>
    <pagination-controls *ngIf="this.whiteListAccounts" (pageChange)="p = $event" class="pagination"></pagination-controls>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
