<div class="content">
  <div class="container">
    <div translate class="prev-page"><i class="fa fa-arrow-left"></i> previous</div>
    <div translate class="divider-title">
      addAccountsC
    </div>
    <div class="step-area">
      <div class="step-left">
        <ul>
          <li (click)="changeStep('new-account/step1')"><span class="icon"><i class="fa fa-share"></i></span><span class="title" translate>step1</span></li>
          <li (click)="changeStep('new-account/step2')"><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>step2</span></li>
          <li (click)="changeStep('new-account/step3')"><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>step3</span></li>
          <li class="active"><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>step4</span></li>
<!--          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title">ADIM 5</span></li>-->

        </ul>
      </div>
      <div class="step-right">
        <div class="step-location">
                        <span class="icon">
                            <i class="fa fa-share"></i>
                        </span>
          <span class="title-info">
                            <span class="step-number" translate>step4</span>
                            <span class="step-name" translate>contact</span>
                        </span>
        </div>
        <div class="step-right-content">
          <div class="form-content">
            <div class="form-group">
              <label for="name" translate>nameSurname</label>
              <input type="text" id="name" [(ngModel)]="accountData.fullName">
            </div>
            <div class="form-group">
              <label for="email" translate>authorized</label>
              <input type="email" id="email" [(ngModel)]="accountData.email" email>
            </div>
            <div class="form-group">
              <label for="phone" translate>number</label>
              <input type="tel" id="phone" [(ngModel)]="accountData.phone" prefix="+90 " mask="(000) 000 00 00">
            </div>
            <div class="form-button">
              <button (click)="finish()" translate>continue</button>
            </div>
          </div>
          <div class="step-right-image">
            <img src="assets/img/new-account-4.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
