import {Injectable} from '@angular/core';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  // apiUrl = 'http://18.197.177.148:1882/api/v1';
  apiUrl = 'https://api.fakeradar.co/api/v1';
  // apiUrl = 'http://192.168.1.52:1882/api/v1';

  constructor(public _location: Location) {
  }

  goPrevPage() {
    this._location.back();
  }

  public shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
}
