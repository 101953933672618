import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AppComponent} from './app.component';
import {GuardComponent} from './guard/guard.component';
import {
  AuthGuardService as AuthGuard
} from './auth/auth-guard.service';
import {ReportComponent} from './report/report.component';
import {NewAccountComponent} from './new-account/new-account.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {SupportComponent} from './support/support.component';
import {NewAccount3Component} from './new-account/new-account3.component';
import {NewAccount4Component} from './new-account/new-account4.component';
import {NewAccount5Component} from './new-account/new-account5.component';
import {NewAccount6Component} from './new-account/new-account6.component';
import {NewAccount2Component} from './new-account/new-account2.component';
import {NewAccount1Component} from './new-account/new-account1.component';
import {AccountDetailComponent} from './account-detail/account-detail.component';
import {ProtectedAccountsComponent} from './protected-accounts/protected-accounts.component';
import {PendingListsComponent} from './pending-lists/pending-lists.component';
import {WhitelistAccountsComponent} from './whitelist-accounts/whitelist-accounts.component';
import {TakedownAccountsComponent} from './takedown-accounts/takedown-accounts.component';

const routes: Routes = [
  // {
  //   path: 'login',
  //   component: LoginComponent
  // },
  // {
  //   path: 'register',
  //   component: RegisterComponent
  // },
  // {
  //   path: 'dashboard',
  //   component: DashboardComponent
  // },
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {
    path: '', component: AppComponent, children: [
      {path: 'login', component: LoginComponent},
      {path: 'register', component: RegisterComponent},
    ]
  },
  // {
  //   path: '', component: GuardComponent, canActivate: [AuthGuard] , children: [
  //     {path: 'dashboard', component: DashboardComponent},
  //   ]
  // }
  {
    path: '', component: GuardComponent, canActivate: [AuthGuard], children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'report', component: ReportComponent},
      {
        path: 'new-account', component: NewAccountComponent, children: [
          {path: '', redirectTo: 'step1', pathMatch: 'full'},
          {path: 'step1', component: NewAccount1Component},
          {path: 'step2', component: NewAccount2Component},
          {path: 'step3', component: NewAccount3Component},
          {path: 'step4', component: NewAccount4Component},
          // {path: 'step5', component: NewAccount5Component},
          {path: 'step5', component: NewAccount6Component}
        ]
      },
      {path: 'change-password', component: ChangePasswordComponent},
      {path: 'support', component: SupportComponent},
      {path: 'suspected-accounts', component: AccountDetailComponent},
      {path: 'protected-accounts', component: ProtectedAccountsComponent},
      {path: 'pending-lists', component: PendingListsComponent},
      {path: 'whitelist-accounts', component: WhitelistAccountsComponent},
      {path: 'takedown-accounts', component: TakedownAccountsComponent},
      {path: 'support', component: SupportComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
