import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {catchError, tap, map} from 'rxjs/operators';
import {GeneralService} from './general.service';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class OutlineService {

  constructor(private http: HttpClient, private general: GeneralService) {


  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(`${error.error.message}`);
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  doLogin(email, pass): Observable<any> {
    return this.http.post(this.general.apiUrl + '/user/login', {'email': email, 'password': pass}, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  doRegister(name, email, password, register_code, firm_name): Observable<any> {
    return this.http.post(this.general.apiUrl + '/user/register', {
      'adsoyad': name,
      'email': email,
      'parola': password,
      'kayit_kodu': register_code,
      'marka_adi': firm_name
    }, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

}
