import {Component, OnInit} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../services/account-service';

@Component({
  selector: 'app-new-account4',
  templateUrl: './new-account4.component.html',
  styleUrls: ['./new-account.component.scss']
})

export class NewAccount4Component implements OnInit {

  accountData: any;

  constructor(private router: Router, private spinner: NgxSpinnerService, private toast: ToastrService, private accountService: AccountService) {

    var data = this.router.getCurrentNavigation().extras.state?.data;
    if (data) {
      this.accountData = data;
    } else {
      this.router.navigate(['new-account'], {replaceUrl: true});
    }
  }

  changeStep(url){
    const navigationExtras: NavigationExtras = {
      state: {
        data: this.accountData
      }
    };
    this.router.navigate([url], navigationExtras);
  }

  finish() {
    if (!this.accountData.phone) {
      this.toast.error('Telefon numarası gereklidir.');
    } else if (!this.accountData.email) {
      this.toast.error('Email adresi gereklidir.');
    } else if (!this.accountData.fullName) {
      this.toast.error('Ad Soyad alanı gereklidir.');
    } else if (!this.validateEmail(this.accountData.email)) {
      this.toast.error('Lütfen geçerli bir email adresi giriniz');
    } else {
      this.spinner.show();
      let userInfo = {
        email: this.accountData.email,
        full_name: this.accountData.fullName,
        is_brand: this.accountData.isBrand,
        facebook: this.accountData.socials.facebook,
        instagram: this.accountData.socials.instagram,
        twitter: this.accountData.socials.twitter,
        youtube: this.accountData.socials.youtube,
        telephone: this.accountData.phone
      };
      this.accountService.createAccount(userInfo).subscribe((data) => {
        this.spinner.hide();
        this.toast.success(data.message);
        this.router.navigate(['pending-lists'], {replaceUrl: true});
      }, (err) => {
        err = JSON.parse(err);
        this.spinner.hide();
        this.toast.error(err.message, 'Hata Nedeni', {extendedTimeOut: 8000, closeButton: true});
        if (err?.statusCode === 5010) {
          this.router.navigate(['new-account/step3'], {state: {data: this.accountData}, replaceUrl: true});
        }
      });

    }
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ngOnInit(): void {
  }

}
