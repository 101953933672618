<div class="content">
  <div class="container">
    <div translate class="prev-page"><i class="fa fa-arrow-left"></i> previous</div>
    <div translate class="divider-title">
      addAccountsC
    </div>
    <div class="step-area">
      <div class="step-left">
        <ul>
          <li (click)="changeStep('new-account/step1')"><span class="icon"><i class="fa fa-share"></i></span><span translate class="title">step1</span></li>
          <li (click)="changeStep('new-account/step1')"><span class="icon"><i class="fa fa-user"></i></span><span translate class="title">step2</span></li>
          <li class="active"><span class="icon"><i class="fa fa-user"></i></span><span translate class="title">step3</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span translate class="title">step4</span></li>
<!--          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title">ADIM 5</span></li>-->
<!--          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title">ADIM 6</span></li>-->
        </ul>
      </div>
      <div class="step-right">
        <div class="step-location">
                        <span class="icon">
                            <i class="fa fa-share"></i>
                        </span>
          <span class="title-info">
                            <span translate class="step-number">step3</span>
                            <span translate class="step-name">connectAcc</span>
                        </span>
        </div>
        <div class="step-right-content">
          <div class="step-right-content-accounts">
            <ul class="input-step">
              <li class="facebook" [class.active]="checkValid(accountData.socials.facebook)" *ngIf="showFacebook">
                                    <span class="is-title">
                                        Facebook
                                    </span>
                <span class="is-input">
                                        <label translate for="username">username</label>
                                        <input type="text" id="username"
                                               [(ngModel)]="accountData.socials.facebook">
                                    </span>
              </li>
              <li class="instagram" [class.active]="checkValid(accountData.socials.instagram)" *ngIf="showInstagram">
                                    <span class="is-title">
                                        Instagram
                                    </span>
                <span class="is-input">
                                        <label translate for="username">username</label>
                                        <input type="text" id="username"
                                               [(ngModel)]="accountData.socials.instagram">
                                    </span>
              </li>
              <li class="twitter" [class.active]="checkValid(accountData.socials.twitter) " *ngIf="showTwitter">
                                    <span class="is-title">
                                        Twitter
                                    </span>
                <span class="is-input">
                                        <label translate for="username">username</label>
                                        <input id="username"
                                               [(ngModel)]="accountData.socials.twitter">
                                    </span>
              </li>
              <li class="youtube" [class.active]="checkValid(accountData.socials.youtube) " *ngIf="showYoutube">
                                    <span class="is-title">
                                        Youtube
                                    </span>
                <span class="is-input">
                                        <label translate for="username">username</label>
                                        <input type="text" id="username"
                                               [(ngModel)]="accountData.socials.youtube">
                                    </span>
              </li>
            </ul>
            <button translate (click)="continue()">check</button>
          </div>
          <div class="step-right-image">
            <img src="assets/img/new-account-3.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
