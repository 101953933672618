<div class="content">
  <div class="container">
    <div translate class="prev-page"><i class="fa fa-arrow-left"></i> previous</div>
    <div translate="" class="divider-title">
      addAccountsC
    </div>
    <div class="step-area">
      <div class="step-left">
        <ul>
          <li (click)="changeStep('new-account/step1')"><span class="icon"><i class="fa fa-share"></i></span><span class="title" translate>step1</span></li>
          <li class="active"><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>step2</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>step3</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>step4</span></li>
<!--          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>ADIM 5</span></li>-->
<!--          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>ADIM 6</span></li>-->
        </ul>
      </div>
      <div class="step-right">
        <div class="step-location">
                        <span class="icon">
                            <i class="fa fa-share"></i>
                        </span>
          <span class="title-info">
                            <span class="step-number" translate>step2</span>
                            <span class="step-name" translate>selectAccountType</span>
                        </span>
        </div>
        <div class="step-right-content">
          <div class="step-right-content-accounts">
            <ul>
              <li (click)="selectType(1)" [class.active]="userData.isBrand === 1" translate>corporateAcc</li>
              <li (click)="selectType(0)" [class.active]="userData.isBrand === 0" translate>sinuglarAcc</li>
            </ul>
            <button translate (click)="continue()">continue</button>
          </div>
          <div class="step-right-image">
            <img src="assets/img/new-account-2.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
