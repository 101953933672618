<div class="content">
  <div class="container">
    <div translate class="prev-page"><i class="fa fa-arrow-left"></i> previous</div>
    <div class="list-top">
      <div translate class="divider-title">
        shutdownedAcc
      </div>
      <div class="account-search-input">
        <input type="search" placeholder="Search.." [(ngModel)]="searchKey">
      </div>
    </div>
    <div class="list-accounts">
      <div class="list-accounts-item"
           *ngFor="let account of this.takeDownAccounts | filter: searchKey | paginate: { itemsPerPage: 10, currentPage: p }">
        <div class="list-accounts-item-platform">
          <i class="fab fa-facebook" *ngIf="account.type == 'facebook'"></i>
          <i class="fab fa-twitter-square" *ngIf="account.type == 'twitter'"></i>
          <i class="fab fa-instagram" *ngIf="account.type == 'instagram'"></i>
          <i class="fab fa-youtube" *ngIf="account.type == 'youtube'"></i>
        </div>
        <div class="list-accounts-item-image" (click)="goProfile(account)">
          <img src="{{account.profile_photo}}">
        </div>
        <div class="list-accounts-item-title" (click)="goProfile(account)">
          <span class="title"
                tooltip="{{(account.suspected_fullname) ? account.suspected_fullname: account.suspected_username}}">{{(account.suspected_fullname) ? account.suspected_fullname : account.suspected_username}}</span>
          <span class="url">@{{account.suspected_username}}</span>
        </div>
        <div class="list-accounts-item-divider"></div>
        <div class="list-accounts-item-number">
          <div class="list-accounts-item-number-icon"><i class="fa fa-poll-h"></i></div>
          <div class="list-accounts-item-number-content">
            <span class="number">{{account.post}}</span>
            <span translate class="title">postC</span>
          </div>
        </div>
        <div class="list-accounts-item-number">
          <div class="list-accounts-item-number-icon"><i class="fa fa-users"></i></div>
          <div class="list-accounts-item-number-content">
            <span class="number">{{account.follower}}</span>
            <span translate class="title">followerC</span>
          </div>
        </div>
        <div class="list-accounts-item-number">
          <div class="list-accounts-item-number-icon"><i class="fa fa-user-plus"></i></div>
          <div class="list-accounts-item-number-content">
            <span class="number">{{account.following}}</span>
            <span translate class="title">followC</span>
          </div>
        </div>
        <!--        <div class="list-accounts-item-divider"></div>-->
        <!--        <div class="list-accounts-item-mail">-->
        <!--          <i class="fa fa-envelope"></i>-->
        <!--          <span class="mail">hello@burakodabas.net</span>-->
        <!--        </div>-->
        <div class="list-accounts-item-divider"></div>
        <div class="list-accounts-item-date">
          <span translate class="date-title">shutdownDate</span>
          <div class="date-text">{{account.takedown_date | date : 'dd.MM.yyyy'}}</div>
        </div>
      </div>
    </div>
    <pagination-controls *ngIf="this.takeDownAccounts" (pageChange)="p = $event"
                         class="pagination"></pagination-controls>
  </div>
</div>
<ngx-spinner></ngx-spinner>
