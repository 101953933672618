<div class="content">
  <div class="container">
    <div class="prev-page"><i class="fa fa-arrow-left"></i> ÖNCEKİ SAYFA</div>
    <div class="divider-title">
      AYLIK RAPOR
    </div>
    <div class="report-area">
      <div class="report-left">
        <div class="report-select-date">
          from <span class="date">Agust 2018</span> to <span class="date">May 2020</span>
        </div>
        <div class="report-lists">
          <ul>
            <li>
                                <span class="icon">
                                    <i class="fas fa-file-pdf"></i>
                                </span>
              <span class="info">
                                    <span class="info-date">12 KASIM 2019</span>
                                    <span class="info-text">PDF İNDİR</span>
                                </span>
              <span class="download">
                                    <i class="fas fa-download"></i>
                                </span>
            </li>
            <li>
                                <span class="icon">
                                    <i class="fas fa-file-pdf"></i>
                                </span>
              <span class="info">
                                    <span class="info-date">12 KASIM 2019</span>
                                    <span class="info-text">PDF İNDİR</span>
                                </span>
              <span class="download">
                                    <i class="fas fa-download"></i>
                                </span>
            </li>
            <li>
                                <span class="icon">
                                    <i class="fas fa-file-pdf"></i>
                                </span>
              <span class="info">
                                    <span class="info-date">12 KASIM 2019</span>
                                    <span class="info-text">PDF İNDİR</span>
                                </span>
              <span class="download">
                                    <i class="fas fa-download"></i>
                                </span>
            </li>
            <li>
                                <span class="icon">
                                    <i class="fas fa-file-pdf"></i>
                                </span>
              <span class="info">
                                    <span class="info-date">12 KASIM 2019</span>
                                    <span class="info-text">PDF İNDİR</span>
                                </span>
              <span class="download">
                                    <i class="fas fa-download"></i>
                                </span>
            </li>
            <li>
                                <span class="icon">
                                    <i class="fas fa-file-pdf"></i>
                                </span>
              <span class="info">
                                    <span class="info-date">12 KASIM 2019</span>
                                    <span class="info-text">PDF İNDİR</span>
                                </span>
              <span class="download">
                                    <i class="fas fa-download"></i>
                                </span>
            </li>
            <li>
                                <span class="icon">
                                    <i class="fas fa-file-pdf"></i>
                                </span>
              <span class="info">
                                    <span class="info-date">12 KASIM 2019</span>
                                    <span class="info-text">PDF İNDİR</span>
                                </span>
              <span class="download">
                                    <i class="fas fa-download"></i>
                                </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="report-right">
        <img src="assets/img/montly-report.png">
      </div>

    </div>
  </div>
</div>
