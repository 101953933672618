import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../services/account-service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  categories = ['TECHNIQUE', 'ACCOUNT', 'REPORT', 'MEMBERSHIP'];
  activeCategory = 'TEKNİK';
  title;
  desc;

  constructor(private toast: ToastrService, private accountService: AccountService, public spinner: NgxSpinnerService, private router: Router) {
    if (this.router.getCurrentNavigation().extras.state?.topic) {
      this.activeCategory = this.router.getCurrentNavigation().extras.state?.topic;
    }

    if (this.router.getCurrentNavigation().extras.state?.title) {
      this.title = this.router.getCurrentNavigation().extras.state?.title;
    }

    if (this.router.getCurrentNavigation().extras.state?.desc) {
      this.desc = this.router.getCurrentNavigation().extras.state?.desc;
    }

  }

  sendMail() {
    if (!this.title || !this.desc) {
      this.toast.error('Boş alan bırakmamalısınız');
    } else {
      this.spinner.show();
      this.accountService.sendMessage(this.activeCategory, this.title, this.desc).subscribe((data: any) => {
        this.spinner.hide();
        this.toast.success(data.message);
        window.location.reload();
      }, (err) => {
        this.toast.error(err.message);
        this.spinner.hide();
      });
    }
  }

  changeCategory(type: any) {
    this.activeCategory = type;
  }

  ngOnInit(): void {
  }

}
