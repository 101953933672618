import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account-service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { GeneralService } from '../services/general.service';

@Component({
  selector: 'app-protected-accounts',
  templateUrl: './protected-accounts.component.html',
  styleUrls: ['./protected-accounts.component.scss']
})
export class ProtectedAccountsComponent implements OnInit {

  protectedAccounts: any;
  mixProtecteds;
  p = 1;
  searchKey: any;


  constructor(private accountService: AccountService, private toast: ToastrService, private spinner: NgxSpinnerService, public _location: Location, private general: GeneralService) {
    this.spinner.show();
    this.accountService.getProtectedAccounts().subscribe((data) => {
      //console.log(data);
      // data.dataPendingList[0].youtubePendingList['type'] = 'youtube';
      // data.dataPendingList[1].instagramPendingList['type'] = 'instagram';
      // data.dataPendingList[2].youtubePendingList['type'] = 'twitter';
      // data.dataPendingList[3].takeDownPendingList['type'] = 'takeDown';

      // this.youtubePendingList = data.dataPendingList[0].youtubePendingList;
      // this.instagramPendingList = data.dataPendingList[1].instagramPendingList;
      // this.twitterPendingList = data.dataPendingList[2].twitterPendingList;
      // this.takeDownPendingList = data.dataPendingList[3].takeDownPendingList;
      let allProtected = this.general.shuffle([...data.protectedAccount[0].YoutubeProtectedAccount, ...data.protectedAccount[1].InstagramProtectedAccount, ...data.protectedAccount[2].TwitterProtectedAccount, ...data.protectedAccount[3].FacebookProtectedAccount, ...data.protectedAccount[4].LinkedinAccount, ...data.protectedAccount[5].ProtectedDomain]);
      this.mixProtecteds = allProtected;
      this.mixProtecteds.sort((val1, val2) => {
        return new Date(val2.created_date).getTime() - new
          Date(val1.created_date).getTime();
      });
      this.spinner.hide();

    }, (err) => {
      this.toast.error(err.message);
      this.spinner.hide();
    });
  }


  ngOnInit(): void {
  }


  goProfile(data: any) {
    // window.open('https://www.' + type + '.com/' + username, '_blank');
    if (data.type === 'instagram') {
      window.open('https://www.' + data.type + '.com/' + data.instagram_username, '_blank');
    } else if (data.type === 'twitter') {
      window.open('https://www.' + data.type + '.com/' + data.twitter_username, '_blank');
    } else if (data.type === 'youtube') {
      window.open('https://www.' + data.type + '.com/' + data.youtube_username, '_blank');
    } else if (data.type === 'facebook') {
      window.open('https://www.' + data.type + '.com/' + data.facebook_username, '_blank');
    } else if (data.type === 'linkedin') {
      window.open('https://www.' + data.type + '.com/' + data.linkedin_username, '_blank');
    } else if (data.type === 'domain') {
      window.open(data.domainame, '_blank');
    } else if (data.is_takedown === 1) {
      window.open('https://www.' + data.type + '.com/' + data.suspected_username, '_blank');
    }
  }
}
