import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  constructor(private dashboardService: DashboardService) {
    if (localStorage.getItem('namesurname') === null) {
      this.dashboardService.dataFromDashboard().subscribe((data) => {
        // console.log(data);
        localStorage.setItem('namesurname', data.user.adsoyad);
      }, (err) => {
        // console.log(err);
      });
    }
  }

  localStorageItem(key: any): string {
    return localStorage.getItem(key);
  }

  ngOnInit(): void {
  }

}
