<div class="content">
  <div class="container">
    <div translate class="prev-page"><i class="fa fa-arrow-left"></i> previous</div>
    <div class="divider-title" translate>
      changePasswordC
    </div>
    <div class="change-password-area">
      <div class="form-content">
        <div class="form-group">
          <label for="old-password" translate>oldPasswordC</label>
          <input type="password" id="old-password" [(ngModel)]="oldPassword">
        </div>
        <div class="form-group">
          <label for="new-password" translate>newPasswordC</label>
          <input type="password" id="new-password" [(ngModel)]="password">
        </div>
        <div class="form-group">
          <label for="new-password2" translate>newPasswordCAgain</label>
          <input type="password" id="new-password2" [(ngModel)]="repeatPassword">
        </div>
        <div class="form-button">
          <button (click)="changePassword()" translate>changeMyPasswordC</button>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
