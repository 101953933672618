import { Component, OnInit } from "@angular/core";
import { OutlineService } from "../services/outline.service";
import { ToastrService } from "ngx-toastr";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { NavigationExtras, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
/* import translate */
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(
    private router: Router,
    private outlineService: OutlineService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    const token = localStorage.getItem("token");
    if (token) {
      this.router.navigate(["dashboard"], { replaceUrl: true });
    }
  }

  doLogin(form: FormGroup) {
    if (form.valid) {
      this.spinner.show();
      this.outlineService
        .doLogin(form.value.email, form.value.password)
        .subscribe(
          (data) => {
            // console.log(data);
            this.toastr.success(data.message, "Başarılı");
            // console.log(data.token);
            localStorage.setItem("token", data.token);
            localStorage.setItem("logined", "true");
            this.spinner.hide();
            const navigationExtras: NavigationExtras = {
              state: {
                token: data.token,
              },
              replaceUrl: true,
            };
            this.router.navigate(["dashboard"], navigationExtras);
            window.location.reload();
          },
          (err) => {
            this.spinner.hide();
            this.toastr.error(err, "Hata");
          }
        );
    } else {
      let errMsg = "Tüm bilgiler gereklidir.";
      if (form.get("email").hasError("email")) {
        errMsg = "Geçerli bir mail adresi gerekli.";
      }
      this.toastr.error(errMsg, "Hata");
    }
  }
}
