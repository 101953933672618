<div class="content">
  <div class="container">
    <div class="step-area">
      <div class="step-left">
        <ul>
          <li><span class="icon"><i class="fa fa-share"></i></span><span class="title" translate>ADIM 1</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>ADIM 2</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>ADIM 3</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>ADIM 4</span></li>
          <li class="active"><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>ADIM 5</span></li>
        </ul>
      </div>
      <div class="step-right">
        <div class="last-step-area">
          <h3>Tebrikler, <span class="new-line" translate>hesabınızı korumaya aldınız!</span></h3>
          <button (click)="goNewOne()" translate>BAŞKA HESAP EKLE?</button>
          <img src="assets/img/new-account-6.png">
        </div>
      </div>
    </div>
  </div>
</div>
