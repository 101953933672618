<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>Kayıt Ol - FakeRadar</title>
</head>
<body>
<div class="login-wrapper">

  <div class="login-wrapper-left">
    <div class="login-wrapper-left-text">
      <p translate>loginFormText1</p>
    </div>
    <div class="login-wrapper-left-image">
      <img src="assets/img/login-image.png">
    </div>
  </div>
  <div class="login-wrapper-right">
    <div class="login-wrapper-right-logo">
      <img src="assets/img/logo.svg">
    </div>
    <div class="login-wrapper-right-form">
      <div class="login-wrapper-right-form-title">
        <h3>Kayıt ol.</h3>
      </div>
      <form [formGroup]="registerForm" (submit)=doRegister(registerForm)>
        <div class="form-group">
          <label for="email">Ad Soyad</label>
          <input type="text" id="name" formControlName="name">
        </div>
        <div class="form-group">
          <label for="firm-name">Kurum Adı</label>
          <input type="text" id="firm-name" formControlName="firm_name">
        </div>
        <div class="form-group">
          <label for="email">YETKİLİ KİŞİNİN MAIL ADRESİ</label>
          <input type="text" id="email" formControlName="email">
        </div>
        <div class="form-group">
          <label for="email">ŞİFRE</label>
          <input type="password" id="password" formControlName="password">
        </div>
        <div class="form-group">
          <label for="password-r">ŞİFRE TEKRAR</label>
          <input type="password" id="password-r" formControlName="confirmPassword">
        </div>
        <div class="form-group">
          <label for="activation-code">AKTİVASYON KODU</label>
          <input type="text" id="activation-code" formControlName="register_code">
        </div>
        <div class="form-group">
          <button type="button" (click)="doRegister(registerForm)">KAYIT OL</button>
        </div>
      </form>
      <div class="not-yet-account">
        <h4>Zaten kayıtlı bir hesabınız var mı?</h4>
        <a routerLink="/login">Giriş yapın.</a>
      </div>
    </div>
  </div>
</div>
</body>
</html>
