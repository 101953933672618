import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../services/account-service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  oldPassword;
  password;
  repeatPassword;

  constructor(private toast: ToastrService, private accountService: AccountService, private router: Router, public spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
  }

  changePassword() {
    if (!this.password || !this.oldPassword || !this.repeatPassword) {
      this.toast.error('Tüm alanları doldurmanız gerekiyor');
    } else if (this.password !== this.repeatPassword) {
      this.toast.error('Girdiğiniz şifreler birbiri ile uyuşmuyor.');
    } else if (this.password.toString().length < 4) {
      this.toast.error('En az 4 karakterli bir şifre belirlemeniz gerekiyor');
    } else {
      this.spinner.show();
      this.accountService.changePassword(this.oldPassword, this.password).subscribe((data: any) => {
        this.toast.success(data.message);
        this.spinner.hide();
        this.router.navigate(['/dashboard'], {replaceUrl: true});
      }, (err) => {
        this.toast.error(err.message);
        this.spinner.hide();
      });
    }
  }

}
