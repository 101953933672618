<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Giriş - FakeRadar</title>
  </head>
  <body>
    <div class="login-wrapper">
      <div class="login-wrapper-left">
        <div class="login-wrapper-left-text">
          <p translate>loginFormText1</p>
        </div>
        <div class="login-wrapper-left-image">
          <img src="assets/img/login-image.png" />
        </div>
      </div>
      <div class="login-wrapper-right">
        <div class="login-wrapper-right-logo">
          <img src="assets/img/logo.svg" />
        </div>
        <div class="login-wrapper-right-form">
          <div class="login-wrapper-right-form-title">
            <h3 translate>login</h3>
          </div>
          <form [formGroup]="loginForm" (submit)="doLogin(loginForm)">
            <div class="form-group">
              <label translate for="email">email</label>
              <input type="text" id="email" formControlName="email" />
            </div>
            <div class="form-group">
              <label translate for="email">password</label>
              <input type="password" id="password" formControlName="password" />
              <!--          <span class="forgot_password">-->
              <!--                        <a href="#">Şifremi Unuttum?</a>-->
              <!--                    </span>-->
            </div>
            <!--        <div class="form-group checkbox">-->
            <!--          <label for="remember-me">Beni hatırla</label>-->
            <!--          <input type="checkbox" id="remember-me">-->
            <!--        </div>-->
            <div class="form-group">
              <button translate type="submit">
                <p translate>loginButton</p>
              </button>
            </div>
          </form>
          <div class="not-yet-account">
            <h4 translate>loginFormQuestion</h4>
            <a routerLink="/register" translate>register</a>
          </div>
        </div>
      </div>
    </div>
    <ngx-spinner></ngx-spinner>
  </body>
</html>
