<div class="content">
  <div class="container">
    <div translate class="prev-page"><i class="fa fa-arrow-left"></i> previous</div>
    <div translate class="divider-title">
      addAccountsC
    </div>
    <div class="step-area">
      <div class="step-left">
        <ul>
          <li class="active"><span class="icon"><i class="fa fa-share"></i></span><span class="title" translate>step1</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>step2</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>step3</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>step4</span></li>
<!--          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title" translate>ADIM 5</span></li>-->
        </ul>
      </div>
      <div class="step-right">
        <div class="step-location">
                        <span class="icon">
                            <i class="fa fa-share"></i>
                        </span>
          <span class="title-info">
                            <span class="step-number" translate>step1</span>
                            <span class="step-name" translate>selectPlatform</span>
                        </span>
        </div>
        <div class="step-right-content">
          <div class="step-right-content-accounts">
            <ul>
              <li (click)="selectPlatform('instagram')" [class.active]="this.accountData.socials.instagram"><span class="icon"><i class="fab fa-instagram"></i></span> Instagram</li>
              <li (click)="selectPlatform('twitter')" [class.active]="this.accountData.socials.twitter"><span class="icon"><i class="fab fa-twitter"></i></span> Twitter</li>
              <li (click)="selectPlatform('youtube')" [class.active]="this.accountData.socials.youtube"><span class="icon"><i class="fab fa-youtube"></i></span> Youtube</li>
            </ul>
            <button (click)="continue()" translate>continue</button>
          </div>
          <div class="step-right-image">
            <img src="assets/img/new-account.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
