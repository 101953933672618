<div class="content">
  <div class="container">
    <div translate class="prev-page"><i class="fa fa-arrow-left"></i> previous</div>

    <div class="list-top">
      <div translate class="divider-title">
        waitingOperations
      </div>
      <div class="account-search-input">
        <input type="search" placeholder="Search.." [(ngModel)]="searchKey">
      </div>
    </div>
    <div class="list-accounts" *ngIf="this.mixPendings">
      <div class="list-accounts-item"
           *ngFor="let pending of this.mixPendings | filter: searchKey | paginate: { itemsPerPage: 10, currentPage: p }">
        <div class="list-accounts-item-platform">
          <i class="fab fa-facebook" *ngIf="pending.type == 'facebook'"></i>
          <i class="fab fa-twitter" *ngIf="pending.type == 'twitter'"></i>
          <i class="fab fa-instagram" *ngIf="pending.type == 'instagram'"></i>
          <i class="fab fa-youtube" *ngIf="pending.type == 'youtube'"></i>
          <i class="fa fa-ban" *ngIf="pending.is_takedown"></i>
        </div>
        <div class="list-accounts-item-image">
          <img src="{{pending.profile_photo}}">
        </div>
        <div class="list-accounts-item-title" *ngIf="pending.type == 'instagram' && !pending.is_takedown">
          <span class="title"
                tooltip="{{(pending.instagram_fullname) ? pending.instagram_fullname : pending.instagram_username}}">{{(pending.instagram_fullname) ? pending.instagram_fullname : pending.instagram_username}}</span>
          <span class="url"
                (click)="goProfile(pending.type,pending.instagram_username)">@{{pending.instagram_username}}</span>
        </div>
        <div class="list-accounts-item-title" *ngIf="pending.type == 'twitter'">
          <span class="title"
                tooltip="{{(pending.twitter_fullname) ? pending.twitter_fullname : pending.twitter_username}}">{{(pending.twitter_fullname) ? pending.twitter_fullname : pending.twitter_username}}</span>
          <span class="url"
                (click)="goProfile(pending.type,pending.twitter_username)">@{{pending.twitter_username}}</span>
        </div>
        <div class="list-accounts-item-title" *ngIf="pending.type == 'youtube'">
          <span class="title" tooltip="{{pending.youtube_title}}">{{pending.youtube_title}}</span>
          <span class="url"
                (click)="goProfile(pending.type,pending.youtube_username)">@{{pending.youtube_username}}</span>
        </div>
        <div class="list-accounts-item-title" *ngIf="pending.is_takedown == 1">
          <span class="title"
                tooltip="{{(pending.suspected_fullname) ? pending.suspected_fullname : pending.suspected_username}}">{{pending.suspected_fullname}}</span>
          <span class="url"
                (click)="goProfile(pending.type,pending.suspected_username)">@{{pending.suspected_username}}</span>
        </div>

        <div class="list-accounts-item-divider"></div>
        <div class="list-accounts-item-number">
          <div class="list-accounts-item-number-icon"><i class="fa fa-poll-h"></i></div>
          <div class="list-accounts-item-number-content">
            <span class="number">{{pending.post | shortNumber}}</span>
            <span translate class="title">postC</span>
          </div>
        </div>
        <div class="list-accounts-item-number">
          <div class="list-accounts-item-number-icon"><i class="fa fa-users"></i></div>
          <div class="list-accounts-item-number-content" *ngIf="pending.type!= 'youtube'">
            <span class="number">{{pending.follower | shortNumber}}</span>
            <span translate class="title">followerC</span>
          </div>
          <div class="list-accounts-item-number-content" *ngIf="pending.type== 'youtube'">
            <span class="number">{{pending.subscriberCount | shortNumber}}</span>
            <span translate class="title">subscribeC</span>
          </div>
        </div>
        <div class="list-accounts-item-number">
          <div class="list-accounts-item-number-icon"><i class="fa fa-user-plus"></i></div>
          <div class="list-accounts-item-number-content" *ngIf="pending.type!= 'youtube'">
            <span class="number">{{pending.following | shortNumber}}</span>
            <span translate class="title">followC</span>
          </div>
          <div class="list-accounts-item-number-content" *ngIf="pending.type== 'youtube'">
            <span class="number">{{pending.viewer_count | shortNumber}}</span>
            <span translate class="title">viewsC</span>
          </div>
        </div>
        <!--        <div class="list-accounts-item-divider"></div>-->
        <!--        <div class="list-accounts-item-mail">-->
        <!--          <i class="fa fa-envelope"></i>-->
        <!--          <span class="mail">hello@burakodabas.net</span>-->
        <!--        </div>-->
        <!--        <div class="list-accounts-item-divider"></div>-->
        <!--        <div class="list-accounts-item-status">-->
        <!--          <span class="status-title">Aktiflik Durumu</span>-->
        <!--          &lt;!&ndash;          <span class="status-text"&ndash;&gt;-->
        <!--          &lt;!&ndash;                [class.active]="account.is_whitelist">{{(account.is_whitelist) ? 'Aktif' : 'Pasif'}}</span>&ndash;&gt;-->
        <!--          <span class="status-text active">Aktif</span>-->
        <!--        </div>-->
        <div class="list-accounts-item-divider"></div>
        <div class="list-accounts-item-date">
          <span translate class="date-title">operationDate</span>
          <div class="date-text">{{pending.created_date | date : 'dd.MM.yyyy'}}</div>
        </div>
        <div class="list-accounts-item-button" (mouseenter)="showListMenu(true, pending.search_id, pending.type)"
             (mouseleave)="showListMenu(false)">
          <button translate>operationsC</button>
          <ul class="list-acount-item-button-menu"
              [class.open-menu]="is_showListMenu && pending.search_id === showMenuId && pending.type == showMenuType">
            <li translate (click)="deletePending(this.pending.search_id, this.pending.type)">deleteOperation</li>
          </ul>
        </div>
      </div>
    </div>
    <pagination-controls *ngIf="this.mixPendings" (pageChange)="p = $event" class="pagination"></pagination-controls>
  </div>
</div>
<ngx-spinner></ngx-spinner>
