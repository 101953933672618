import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { GuardComponent } from "./guard/guard.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { HttpClientModule } from "@angular/common/http";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JwtModuleOptions, JwtModule } from "@auth0/angular-jwt";
import { AccountDetailComponent } from "./account-detail/account-detail.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { SupportComponent } from "./support/support.component";
import { ReportComponent } from "./report/report.component";
import { NewAccountComponent } from "./new-account/new-account.component";
import { NewAccount2Component } from "./new-account/new-account2.component";
import { NewAccount3Component } from "./new-account/new-account3.component";
import { NewAccount4Component } from "./new-account/new-account4.component";
import { NewAccount5Component } from "./new-account/new-account5.component";
import { NewAccount6Component } from "./new-account/new-account6.component";
import { NewAccount1Component } from "./new-account/new-account1.component";

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMaskModule } from "ngx-mask";
import { IConfig } from "ngx-mask";

import { TooltipModule } from "ng2-tooltip-directive";
import { PendingListsComponent } from "./pending-lists/pending-lists.component";
import { WhitelistAccountsComponent } from "./whitelist-accounts/whitelist-accounts.component";
import { TakedownAccountsComponent } from "./takedown-accounts/takedown-accounts.component";
import { ProtectedAccountsComponent } from "./protected-accounts/protected-accounts.component";
import { ShortNumberPipe } from "./pipes/short-number.pipe";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgScrollbarModule } from "ngx-scrollbar";
import { SmoothScrollModule } from "ngx-scrollbar/smooth-scroll";

export const maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: () => localStorage.getItem("token"),
    whitelistedDomains: ["localhost:4200", "*"],
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    GuardComponent,
    SidebarComponent,
    AccountDetailComponent,
    ChangePasswordComponent,
    SupportComponent,
    ReportComponent,
    NewAccountComponent,
    NewAccount1Component,
    NewAccount2Component,
    NewAccount3Component,
    NewAccount4Component,
    NewAccount5Component,
    NewAccount6Component,
    PendingListsComponent,
    WhitelistAccountsComponent,
    TakedownAccountsComponent,
    ProtectedAccountsComponent,
    ShortNumberPipe,
  ],
  imports: [
    SmoothScrollModule,
    NgScrollbarModule,
    NgxMaskModule.forRoot(maskOptions),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    JwtModule.forRoot(JWT_Module_Options),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    NgxSpinnerModule,
    TooltipModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
