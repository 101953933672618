
  <div class="sidebar-menu">
    <ul>
      <li routerLinkActive="active"><a translate routerLink="/dashboard"><i class="ri-home-smile-fill"></i>homepage</a></li>
      <span translate class="sidebar-divider">accounts</span>
      <li routerLinkActive="active"><a translate routerLink="/protected-accounts"><i class="ri-shield-user-fill"></i>protectedAcc</a></li>
      <li routerLinkActive="active"><a translate routerLink="/suspected-accounts"><i class="ri-user-unfollow-fill"></i>suspiciousdAcc</a></li>
      <li routerLinkActive="active"><a translate routerLink="/whitelist-accounts"><i class="ri-user-follow-fill"></i>trustedAcc</a></li>
      <li routerLinkActive="active"><a translate routerLink="/takedown-accounts"><i class="ri-user-shared-fill"></i>closedAcc</a></li>
      <span translate class="sidebar-divider">operations</span>
      <li routerLinkActive="active"><a translate routerLink="/new-account/step1"><i class="ri-user-shared-fill"></i>addAccounts</a></li>
      <li routerLinkActive="active"><a translate routerLink="/pending-lists"><i class="ri-user-shared-fill"></i>waitingOp</a></li>
<!--      <span class="sidebar-divider">RAPORLAR</span>-->
<!--      <li routerLinkActive="active"><a href="#"><i class="ri-user-shared-fill"></i>Aylık Raporlar</a></li>-->
      <span translate class="sidebar-divider">accountOp</span>
      <li routerLinkActive="active"><a translate routerLink="/change-password"><i class="ri-user-shared-fill"></i>changePW</a></li>
      <li routerLinkActive="active"><a translate routerLink="/support"><i class="ri-user-shared-fill"></i>support</a></li>
      <li routerLinkActive="active"><a translate href="#" (click)="logout()"><i class="ri-user-shared-fill"></i>safeExit</a></li>


    </ul>
  </div>
  <div class="sidebar-footer">
    <img src="assets/img/developedby.png">
  </div>

