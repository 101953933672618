<div class="content">
  <div class="container">
    <div class="prev-page"><i class="fa fa-arrow-left"></i> ÖNCEKİ SAYFA</div>
    <div class="divider-title">
      HESAP EKLEME İŞLEMLERİ
    </div>
    <div class="step-area">
      <div class="step-left">
        <ul>
          <li><span class="icon"><i class="fa fa-share"></i></span><span class="title">ADIM 1</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title">ADIM 2</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title">ADIM 3</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title">ADIM 4</span></li>
          <li class="active"><span class="icon"><i class="fa fa-user"></i></span><span class="title">ADIM 5</span></li>
          <li><span class="icon"><i class="fa fa-user"></i></span><span class="title">ADIM 6</span></li>
        </ul>
      </div>
      <div class="step-right">
        <div class="step-location">
                        <span class="icon">
                            <i class="fa fa-share"></i>
                        </span>
          <span class="title-info">
                            <span class="step-number">ADIM 5</span>
                            <span class="step-name">HESABI KORUMAYA ALIN</span>
                        </span>
        </div>
        <div class="step-right-content">
          <div class="form-image-content">
            <div class="image-select">
              <div class="image-select-text">
                <i class="ri-image-line"></i> Fotoğraf Ekleyin
                <div class="image-select-button">
                  <button>SEÇ</button>
                </div>
              </div>
            </div>
            <h3>Eklenen Fotoğraflar</h3>
            <div class="selected-images">
              <img src="assets/img/login-image.png">
              <img src="assets/img/login-image.png">
              <img src="assets/img/login-image.png">
            </div>
          </div>
          <div class="step-right-image">
            <img src="assets/img/new-account-5.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
