import {Component, OnInit} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-new-account3',
  templateUrl: './new-account3.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccount3Component implements OnInit {

  focusInput: string;
  accountData: any;
  showFacebook: boolean = false;
  showInstagram: boolean = false;
  showTwitter: boolean = false;
  showYoutube: boolean = false;

  constructor(private router: Router, private toast: ToastrService) {
    var data = this.router.getCurrentNavigation().extras.state?.data;
    if (data) {
      this.accountData = data;
      if (data.socials.facebook) {
        this.showFacebook = true;
        this.accountData.socials.facebook = '';
      }
      if (data.socials.twitter) {
        this.showTwitter = true;
        this.accountData.socials.twitter = '';
      }
      if (data.socials.instagram) {
        this.accountData.socials.instagram = '';
        this.showInstagram = true;
      }
      if (data.socials.youtube) {
        this.showYoutube = true;
        this.accountData.socials.youtube = '';
      }
    } else {
      this.router.navigate(['new-account'], {replaceUrl: true});
    }
  }

  checkValid(value: any) {
    if (value === true) {
      return false;
    } else if (value.toString().length > 0) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
  }

  changeStep(url){
    const navigationExtras: NavigationExtras = {
      state: {
        data: this.accountData
      }
    };
    this.router.navigate([url], navigationExtras);
  }

  continue() {
    if (this.showFacebook && !(this.accountData.socials.facebook.toString().length > 0)) {
      this.toast.error('Facebook kullanıcı adını girmelisiniz.');
    } else if (this.showTwitter && !(this.accountData.socials.twitter.toString().length > 0)) {
      this.toast.error('Twitter kullanıcı adını girmelisiniz.');
    } else if (this.showYoutube && !(this.accountData.socials.youtube.toString().length > 0)) {
      this.toast.error('Youtube kullanıcı adını girmelisiniz.');
    } else if (this.showInstagram && !(this.accountData.socials.instagram.toString().length > 0)) {
      this.toast.error('Instagram kullanıcı adını girmelisiniz.');
    } else {
      let extra: NavigationExtras = {
        state: {
          data: this.accountData
        },
        replaceUrl: true
      };
      this.router.navigate(['new-account/step4'], extra);
    }
  }
}
