import {Component, OnInit} from '@angular/core';
import {Validators, FormBuilder, FormGroup} from '@angular/forms';
import {OutlineService} from '../services/outline.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;

  constructor(private outlineService: OutlineService, private toastr: ToastrService, private formBuilder: FormBuilder, private router: Router) {
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      register_code: ['', [Validators.required]],
      firm_name: ['', [Validators.required]],
    }, {validator: this.checkPasswords});
  }

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    if (token) {
      this.router.navigate(['dashboard'], {replaceUrl: true});
    }
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : {notSame: true};
  }

  doRegister(form: FormGroup) {
    if (form.valid) {
      this.outlineService.doRegister(form.value.name, form.value.email, form.value.password, form.value.register_code, form.value.firm_name).subscribe((data) => {
        this.toastr.success('Kayıt başarılı. Hemen giriş yapabilirsiniz', 'Tebrikler');
        this.router.navigate(['login'], {replaceUrl: true});
      }, (err) => {
        this.toastr.error(err, 'Hata');
      });
    } else {
      var errMsg = 'Tüm bilgiler gereklidir.';
      if (form.get('email').hasError('email')) {
        errMsg = 'Geçerli bir mail adresi gerekli.';
      } else if (form.hasError('notSame')) {
        errMsg = 'Parolalar birbiri ile uyuşmuyor.';
      }
      this.toastr.error(errMsg, 'Hata');
    }

  }

}
