import {Component, OnInit} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-new-account2',
  templateUrl: './new-account2.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccount2Component implements OnInit {
  userData: any;

  constructor(private router: Router, private toast: ToastrService) {
    let data = this.router.getCurrentNavigation().extras.state?.data;
    if (!data) {
      this.router.navigate(['new-account'], {replaceUrl: true});
    } else {
      this.userData = data;
    }
  }

  selectType(type: number) {
    this.userData.isBrand = type;
  }

  continue() {
    if (typeof this.userData.isBrand === 'undefined') {
      this.toast.error('Lütfen hesap türünü seçiniz');
    } else {
      let navigationExtras: NavigationExtras = {
        state: {
          data: this.userData
        }
      };
      this.router.navigate(['new-account/step3'], navigationExtras);
    }
  }

  changeStep(url) {
    const navigationExtras: NavigationExtras = {
      state: {
        data: this.userData
      }
    };
    this.router.navigate([url], navigationExtras);
  }

  ngOnInit(): void {
  }

}
