<div class="content">
  <div class="container">
    <div translate class="prev-page"><i class="fa fa-arrow-left"></i> previous</div>
    <!--    <div class="account-panel">-->
    <!--      <div class="active-account">-->
    <!--        <div class="active-account-img">-->
    <!--          <img src="assets/img/is-bankasi-logo.jpg">-->
    <!--        </div>-->
    <!--        <div class="active-account-title">-->
    <!--          <span class="title">Türkiye İş Bankası</span>-->
    <!--          <span class="url">@isbankasi</span>-->
    <!--        </div>-->
    <!--        <div class="active-account-numbers">-->
    <!--          <div class="active-account-numbers-item">-->
    <!--                                <span class="item-icon">-->
    <!--                                    <i class="fa fa-file"></i>-->
    <!--                                </span>-->
    <!--            <span class="item-number">-->
    <!--                                    2,495-->
    <!--                                </span>-->
    <!--            <span class="item-number-title">-->
    <!--                                    POST-->
    <!--                                </span>-->
    <!--          </div>-->
    <!--          <div class="active-account-numbers-item">-->
    <!--                                <span class="item-icon">-->
    <!--                                    <i class="fa fa-user"></i>-->
    <!--                                </span>-->
    <!--            <span class="item-number">-->
    <!--                                    1,9M-->
    <!--                                </span>-->
    <!--            <span class="item-number-title">-->
    <!--                                    TAKİPÇİ-->
    <!--                                </span>-->
    <!--          </div>-->
    <!--          <div class="active-account-numbers-item">-->
    <!--                                <span class="item-icon">-->
    <!--                                    <i class="fa fa-user-check"></i>-->
    <!--                                </span>-->
    <!--            <span class="item-number">-->
    <!--                                    45-->
    <!--                                </span>-->
    <!--            <span class="item-number-title">-->
    <!--                                    TAKİP-->
    <!--                                </span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="active-account-risk-level">-->
    <!--          <p>%82 RİSK SEVİYESİ</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="account-select">-->
    <!--        <p>Farkı bir hesap seçin</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="list-top">
      <div translate class="divider-title">
        suspicious
      </div>
      <div class="account-search-input">
        <input type="search" placeholder="Search.." [(ngModel)]="searchKey">
      </div>
    </div>
    <!--      <span class="filter">-->
    <!--                       <i class="fa fa-filter"></i>-->
    <!--                    </span>-->
    <!--    </div>-->
    <div class="list-accounts">
      <div class="list-accounts-item" *ngFor="let account of this.suspectedAccounts?.slice().reverse() | filter: searchKey | paginate: { itemsPerPage: 10, currentPage: p }">
        <div class="list-accounts-item-platform">
          <i class="fab fa-facebook" *ngIf="account.type == 'facebook'"></i>
          <i class="fab fa-twitter-square" *ngIf="account.type == 'twitter'"></i>
          <i class="fab fa-instagram" *ngIf="account.type == 'instagram'"></i>
          <i class="fab fa-youtube" *ngIf="account.type == 'youtube'"></i>
        </div>
        <div class="list-accounts-item-image" (click)="goProfile(account)">
          <img src="{{account.suspected_photo}}">
        </div>
        <div class="list-accounts-item-title" (click)="goProfile(account)">
          <span class="title"
                tooltip="{{(account.full_name) ? account.full_name: account.username}}">{{(account.full_name) ? account.full_name : account.username}}</span>
          <span class="url">@{{account.username}}</span>
        </div>
        <div class="list-accounts-item-divider"></div>
        <div class="list-accounts-item-number">
          <div class="list-accounts-item-number-icon"><i class="fa fa-poll-h"></i></div>
          <div class="list-accounts-item-number-content">
            <span class="number">{{account.post | shortNumber}}</span>
            <span translate class="title">postC</span>
          </div>
        </div>
        <div class="list-accounts-item-number">
          <div class="list-accounts-item-number-icon"><i class="fa fa-users"></i></div>
          <div class="list-accounts-item-number-content">
            <span class="number">{{account.follower | shortNumber}}</span>
            <span translate class="title">followerC</span>
          </div>
        </div>
        <div class="list-accounts-item-number">
          <div class="list-accounts-item-number-icon"><i class="fa fa-user-plus"></i></div>
          <div class="list-accounts-item-number-content">
            <span class="number">{{account.following | shortNumber}}</span>
            <span translate class="title">followC</span>
          </div>
        </div>
        <!--        <div class="list-accounts-item-divider"></div>-->
        <!--        <div class="list-accounts-item-mail">-->
        <!--          <i class="fa fa-envelope"></i>-->
        <!--          <span class="mail">hello@burakodabas.net</span>-->
        <!--        </div>-->
        <div class="list-accounts-item-divider"></div>
        <div class="list-accounts-item-status">
          <span translate class="status-title">activityStatus</span>
          <!--          <span class="status-text"-->
          <!--                [class.active]="account.is_whitelist">{{(account.is_whitelist) ? 'Aktif' : 'Pasif'}}</span>-->
          <span translate class="status-text active">active</span>
        </div>
        <div class="list-accounts-item-divider"></div>
        <div class="list-accounts-item-date">
          <span translate class="date-title">detectDate</span>
          <div class="date-text">{{account.created_date | date : 'dd.MM.yyyy'}}</div>
        </div>
        <div class="list-accounts-item-button" (mouseenter)="showListMenu(true, account.blacklist_id)"
             (mouseleave)="showListMenu(false)">
          <button translate>operationsC</button>
          <ul class="list-acount-item-button-menu"
              [class.open-menu]="is_showListMenu && account.blacklist_id === showMenuId">
            <li translate (click)="addWhiteList(account.blacklist_id)">addTrusted</li>
            <li translate (click)="doTakeDown(account.blacklist_id)">closeAcc</li>
          </ul>
        </div>
      </div>
    </div>
    <pagination-controls *ngIf="this.suspectedAccounts" (pageChange)="p = $event" class="pagination"></pagination-controls>
  </div>
</div>
<ngx-spinner></ngx-spinner>
